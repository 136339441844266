import React from 'react'
import { graphql } from "gatsby"
import { config, useSpring, animated } from 'react-spring'
import styled from 'styled-components'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'

const Description = styled(animated.div)`
  max-width: 960px;
  letter-spacing: -0.003em;
  --baseline-multiplier: 0.179;
  --x-height-multiplier: 0.35;
  line-height: 1.58;
  padding-top: 0.5em;
`

const Announcements = ({ data }) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const titleAnimation = useSpring({ config: config.slow, delay: 300, from: { opacity: 0 }, to: { opacity: 1 } })
  const descAnimation = useSpring({ config: config.slow, delay: 600, from: { opacity: 0 }, to: { opacity: 1 } })

  return (
    <Layout color="#90BDDF">
      <SEO title="Announcements | Kiki" desc="Kiki's Tattoo Service | @ink.by.kiki | Vancouver, BC" />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 10]}>
        <animated.h1 style={titleAnimation}>Announcements</animated.h1>
        <Description style={descAnimation}>
          <h4><i>{data.allMarkdownRemark.totalCount} Posts</i></h4>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <div style={{paddingBottom: '1em'}} key={node.id}>
              <h3>
                {node.frontmatter.title}{" "}
                <span style={{fontSize: '20px'}}>
                  | {node.frontmatter.date}
                </span>
              </h3>
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: node.html }}
              />
            </div>
          ))}
        </Description>
      </AnimatedBox>
    </Layout>
  )
}

export default Announcements

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          html
        }
      }
    }
  }
`